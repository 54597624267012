import React, { useContext } from "react";
import {useNavigate} from "react-router-dom";

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import { theme } from "../../../theme";
import ActionMenu from "./actionMenu";

const Table = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    <span>Job ID</span>, 
                                    <span>Job create at</span>, 
                                    <span>Job duration<br/>(min)</span>,
                                    <span>Rate<br/>(per hour)</span>, 
                                    <span>TRADESPERSON Charge</span>,
                                    <span>Company<br/>Commission</span>,
                                    <span>Sub total</span>,
                                    <span>Company<br/>commission<br/>(Materials)</span>,
                                    <span>Materials<br/>charge<br/>(TRADESPERSON)</span>,
                                    'VAT',
                                    'Total',
                                    'Pay date',
                                    <span>Released<br/>payment</span>,
                                    <span></span>,
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        {text}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.payments.length > 0 ? (
                                <>
                                    {context.state.payments.map((payment, index) => (
                                        <tr key={index}>
                                            {[
                                                payment.job_id,
                                                payment.job_create_at,
                                                payment.ftm_time_diff+' min',
                                                `${theme.currency} ${payment.ftm_hourly_rate}`,
                                                `${theme.currency} ${payment.job_ftm_value}`,
                                                `${theme.currency} ${payment.company_commission}`,
                                                `${theme.currency} ${payment.job_value}`,
                                                `${theme.currency} ${payment.total_extra_payments_company_commission}`,
                                                `${theme.currency} ${payment.total_extra_payments_ftm_payment}`,
                                                `${theme.currency} ${payment.vat}`,
                                                `${theme.currency} ${payment.final_total_value_with_vat}`,
                                                payment.pay_date != null ? payment.pay_date : "N/A",
                                                payment?.job_is_pay_ftm === 1 ? (
                                                    <i 
                                                        className="material-icons text-lg position-relative" 
                                                        style={{color: 'green'}}
                                                    >
                                                        check_circle
                                                    </i>
                                                ) : (
                                                    <i 
                                                        className="material-icons text-lg position-relative" 
                                                        style={{color: 'red'}}
                                                    >
                                                        cancel
                                                    </i>
                                                ),
                                                <td>
                                                    <ActionMenu
                                                        job_id={ payment.job_id}
                                                    />
                                                </td>
                                            ].map((text, index) => (
                                                <td className="align-middle" key={index}>
                                                    <span className="text-secondary text-sm font-weight-bold">
                                                        {text}
                                                    </span>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="10">There are no payment records associated with this Tradeperson</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;