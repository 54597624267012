import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { GET } from '../../API/get';
import { map_settings } from '../../map';
import { theme } from '../../theme';
import { getUserInfo } from '../../storage';
import { POST } from '../../API/post';
import { jobsRef } from '../../firebase';
import { isShowChatNotifications } from '../../utils';

export const getJobDetails = async (job_id, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET("get-job-details/"+job_id);
    console.log("🚀 ~ getJobDetails ~ response:", response)

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            job_details: response.details, 
            images: response.images, 
            appointments: response.appointments, 
            ftms: response.ftms,
            extra_payments: response.extra_payments,
            job_status_change_logs: response.job_status_change_logs
        });
    }
}

export const getJobDetailsInFirebase = async (job_firebase_document_id, dispatch) => {
    console.log('job_firebase_document_id :', job_firebase_document_id);
    if(job_firebase_document_id){
        let query = jobsRef.doc(job_firebase_document_id);

        query.onSnapshot(async (doc) => {
            if (doc.exists) {
                console.log("getJobDetailsInFirebase doc :", {
                    id: doc.id,
                    ...doc.data()
                });

                dispatch({
                    type: 'JOB_UNREAD_MESSAGE_COUNT',
                    job_unread_message_count: doc.data().job_admin_unread_message_count
                });
            } 
        });
    }
}

export const addJobLocation = (option) => {
    if(option.map){
        let bounds = new option.google.maps.LatLngBounds();

        let job_lat = parseFloat(option.job_latitude);
        let job_lng = parseFloat(option.job_longitude);

        let ftm_LatLng = new option.google.maps.LatLng(job_lat, job_lng);
        bounds.extend(ftm_LatLng);

        new option.google.maps.Marker({
            position: { lat: job_lat, lng: job_lng },
            icon:map_settings.customer_map_icon ,
            map: option.map,
        });

        
        option.map.setCenter(ftm_LatLng);
        option.map.setZoom(10);
        
    }
}

export const refundPayment = (option) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to refund payment",
        icon: 'warning',
        input: 'text',
        showCancelButton: true,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.cancel_btn_code,
        confirmButtonText: 'Yes, refund it!',
        showLoaderOnConfirm: true,
        preConfirm: async (payment_value) => {
            console.log('payment_value :', payment_value);
            if(isNaN(Number(payment_value))){
                Swal.showValidationMessage('Please enter a valid numeric refund value.');
            }else{
                let response = await POST("job/payment/refund", {
                    payment_id : option.payment_id,
                    payment_value : payment_value
                });

                return response;
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        console.log('result :', result);
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                getJobDetails(option.job_id, option.dispatch);
            }else{
                Swal.fire({
                    title: "ERROR",
                    text: result.value.message,
                    icon: "error"
                });
            }
        }
    })
}

export const openChatView = async (document_id, dispatch, job_id) => {
    try {
        isShowChatNotifications(job_id, "NEW_DIRECT_MESSAGE");

        // Get user info
        let user_info = getUserInfo();
        let ref = jobsRef.doc(document_id);

        ref.update({
            job_last_message_seen_user: user_info.admin_firebase_document_id,
            job_last_message_seen_user_timestamp: new Date().getTime()
        });

        dispatch({
            type: 'IS_SHOW_CHAT',
            is_show_chat: true
        });

    } catch (error) {
        console.error("Error open chat documents:", error);
    }
}